import _ from "lodash";
import { arrayToObject } from "./utils";
import authClient from "./api/auth";
import { createStore } from "vuex";
import moment from "moment";
import actuatorsClient from "./api/actuators";
import feedbackClient from "./api/feedback";
import reportsClient from "./api/reports";
import sensorInfoClient from "./api/sensorInfo";
import sensorsClient from "./api/sensors";
import sensorsConfigurationClient from "./api/sensorsConfiguration";
import usersClient from "./api/users";
import venueGridClient from "./api/venueGrid";
import venueInfoClient from "./api/venueInfo";
import venuesClient from "./api/venues";
import energyMetersClient from "./api/energy_meters";

export default createStore({
  state() {
    return {
      page: "actuators",
      user: {},
      isLoggedIn: false,
      loadingVenues: true,
      loadingActuators: true,
      loadingEnergyMeters: false,
      venues: [],
      venueInfo: {
        productivity: null,
        cognition: null,
        absenteeismLow: null,
        absenteeismHigh: null,
      },
      sensorInfo: {
        productivity: null,
        cognition: null,
        absenteeismLow: null,
        absenteeismHigh: null,
      },
      sensorInfos: [],
      venueInfos: [],
      venueActuators: [],
      setpointPresets: {},
      actuatorLogs: [],
      actuatorAlerts: [],
      venueActuatorsAlerts: [],
      highlightedActuatorId: null,
      selectedActuatorId: null,
      venueGrid: {},
      lastUpdatedSensors: "",
      lastUpdatedActuators: "",
      reportSensors: {},
      currentVenue: {},
      currentSensor: null,
      indexedSensorData: {},
      selectedDate: moment().startOf("day"),
      indexedSensorQueryData: {},
      configurationData: {},
      nh3TimeSeries: {
        margin: {
          top: 10,
          right: 10,
          bottom: 20,
          left: 10,
        },
        data: [],
        unit: "ppm",
        minRange: [0, 50],
        thresholds: [100, 200],
        curveColor: "#ff4500",
      },
      h2sTimeSeries: {
        margin: {
          top: 10,
          right: 10,
          bottom: 20,
          left: 10,
        },
        data: [],
        unit: "ppm",
        minRange: [0, 50],
        thresholds: [100, 200],
        curveColor: "#ff8c00",
      },
      co2TimeSeries: {
        margin: {
          top: 10,
          right: 10,
          bottom: 20,
          left: 10,
        },
        data: [],
        unit: "ppm",
        minRange: [400, 450],
        thresholds: [700, 900],
        curveColor: "#00b2ee",
      },
      temperatureTimeSeries: {
        margin: {
          top: 10,
          right: 10,
          bottom: 20,
          left: 10,
        },
        data: [],
        unit: "°C",
        minRange: [15, 25],
        curveColor: "#66cd00",
      },
      humidityTimeSeries: {
        margin: {
          top: 10,
          right: 10,
          bottom: 20,
          left: 10,
        },
        data: [],
        unit: "%",
        minRange: [30, 55],
        curveColor: "#ff1493",
      },
      voltageTimeSeries: {
        margin: { top: 10, right: 10, bottom: 20, left: 10 },
        data: [],
        unit: "V",
        minRange: [200, 240],
        thresholds: [190, 250],
        curveColor: "#4B0082",
      },
      powerTimeSeries: {
        margin: { top: 10, right: 10, bottom: 20, left: 10 },
        data: [],
        unit: "W",
        minRange: [0, 1000],
        thresholds: [500, 1500],
        curveColor: "#1E90FF",
      },
      currentTimeSeries: {
        margin: { top: 10, right: 10, bottom: 20, left: 10 },
        data: [],
        unit: "mA",
        minRange: [0, 10],
        thresholds: [15, 20],
        curveColor: "#FF69B4",
      },
      energySumTimeSeries: {
        margin: { top: 10, right: 10, bottom: 20, left: 10 },
        data: [],
        unit: "Wh",
        minRange: [0, 100],
        curveColor: "#32CD32",
      },
      factorTimeSeries: {
        margin: { top: 10, right: 10, bottom: 20, left: 10 },
        data: [],
        unit: "%",
        minRange: [0, 1],
        thresholds: [0.8, 0.9],
        curveColor: "#FF8C00",
      },
      stateTimeSeries: {
        margin: { top: 10, right: 10, bottom: 20, left: 10 },
        data: [],
        unit: "",
        curveColor: "#8A2BE2",
      },
      actuatorUserUpdatePending: false,
      isActuatorLogsModalOpen: false,
      isActuatorScheduleModalOpen: false,
      isFeedbackSlideOverOpen: false,
      dailyAccumulatedKwh: null,
      monthlyAccumulatedKwh: null,
      dailyAverageKwh: null,
      actuatorsGridHeight: 0,
      statsInlineHeight: 0,
    };
  },

  getters: {
    isScraper() {
      return (
        localStorage.getItem("report-token") == process.env.VUE_APP_REPORT_TOKEN
      );
    },
    defaultVenue(state) {
      const lastVenueId = localStorage.getItem("currentVenueId");
      return _.find(state.venues, { id: lastVenueId }) || state.venues[0];
    },
    sensorHasData(state) {
      return (sensor) => {
        const sensorData = state.indexedSensorData[sensor.id];
        return Boolean(sensorData && _.size(sensorData));
      };
    },
    sensorHasQueryData(state) {
      return (sensor) => {
        return Boolean(state.indexedSensorQueryData[sensor.id]);
      };
    },
    sensorDataArray(state, getters) {
      return (sensor) => {
        if (getters.sensorHasData(sensor)) {
          return Object.values(state.indexedSensorData[sensor.id]);
        }
        return [];
      };
    },
    sensorQueryDataArray(state, getters) {
      return (sensor) => {
        if (!sensor) return [];
        if (getters.sensorHasQueryData(sensor)) {
          return Object.values(state.indexedSensorQueryData[sensor.id]);
        } else if (getters.sensorHasData(sensor)) {
          return Object.values(state.indexedSensorData[sensor.id]);
        }
        return [];
      };
    },
    nthSensorDatum(_state, getters) {
      return (sensor, n) => {
        return _.nth(getters.sensorDataArray(sensor), n);
      };
    },
  },

  mutations: {
    setLastUpdatedSensors(state, timestamp) {
      state.lastUpdatedSensors = timestamp;
    },
    setLastUpdatedActuators(state, timestamp) {
      state.lastUpdatedActuators = timestamp;
    },
    setUser(state, user) {
      state.user = user;
    },
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setPage(state, page) {
      state.page = page;
    },
    setReportSensors(state, sensors) {
      state.reportSensors = sensors;
    },
    setVenues(state, venues) {
      state.venues = venues;
    },
    setSensorInfo(state, sensorInfo) {
      state.sensorInfo = sensorInfo;
    },
    setSensorInfos(state, sensorInfos) {
      state.sensorInfos = sensorInfos;
    },
    setVenueInfo(state, venueInfo) {
      state.venueInfo = venueInfo;
    },
    setVenueInfos(state, venueInfos) {
      state.venueInfos = venueInfos;
    },
    setVenueActuators(state, venueActuators) {
      state.venueActuators = venueActuators;
    },
    setVenueActuator(state, actuatorData) {
      const actuatorId = actuatorData.id;
      const index = state.venueActuators.findIndex(
        (actuator) => actuator.id === actuatorId
      );

      if (index !== -1) {
        state.venueActuators[index] = actuatorData;
      } else {
        state.venueActuators.push(actuatorData);
      }
    },
    setSetpointPresets(state, setpointPresets) {
      const presetDict = {};
      setpointPresets.forEach((preset) => {
        presetDict[preset.id] = preset;
      });
      state.setpointPresets = presetDict;
    },
    updateCurrentSetpointPresetUuid(state, payload) {
      const { index, presetUuid } = payload;
      if (state.venueActuators[index]) {
        state.venueActuators[index].currentSetpointPresetUuid = presetUuid;
      }
    },
    setActuatorLogs(state, actuatorLogs) {
      state.actuatorLogs = actuatorLogs;
    },
    setActuatorsAlerts(state, actuatorAlerts) {
      state.actuatorAlerts = actuatorAlerts;
    },
    setVenueActuatorsAlerts(state, venueActuatorsAlerts) {
      state.venueActuatorsAlerts = venueActuatorsAlerts;
    },
    setSelectedActuatorId(state, actuatorId) {
      state.selectedActuatorId = actuatorId;
    },
    setVenueGrid(state, venueGrid) {
      state.venueGrid = venueGrid;
    },
    setCurrentVenue(state, venue) {
      localStorage.setItem("currentVenueId", venue.id);
      state.currentVenue = venue;
    },
    setCurrentSensor(state, sensor) {
      state.currentSensor = sensor;
    },
    setSensorQueryData(state, sensor) {
      const newSensorData = sensor.timeseries;
      const parsedNewData = newSensorData.map(({ timestamp, values }) => ({
        date: moment(timestamp),
        values,
      }));
      state.indexedSensorQueryData[sensor.id] = arrayToObject(
        parsedNewData,
        "date"
      );
    },
    updateSensorData(state, sensor) {
      const newData = sensor.timeseries;
      const parsedNewData = newData.map(({ timestamp, values }) => ({
        date: moment(timestamp),
        values,
      }));
      state.indexedSensorData[sensor.id] = {
        ...state.indexedSensorData[sensor.id],
        ...arrayToObject(parsedNewData, "date"),
      };
    },
    setSensorConfiguration(state, configuration) {
      state.configurationData = configuration;
    },
    updateVenueConfig(state, venue) {
      state.currentVenue = venue;
    },
    setSelectedDate(state, date) {
      state.selectedDate = date;
    },
    setActuatorUserUpdatePending(state) {
      state.actuatorUserUpdatePending = true;
    },
    clearActuatorUserUpdatePending(state) {
      state.actuatorUserUpdatePending = false;
    },
    openActuatorLogsModal(state) {
      state.isActuatorLogsModalOpen = true;
    },
    closeActuatorLogsModal(state) {
      state.isActuatorLogsModalOpen = false;
    },
    openActuatorScheduleModal(state) {
      state.isActuatorScheduleModalOpen = true;
    },
    closeActuatorScheduleModal(state) {
      state.isActuatorScheduleModalOpen = false;
    },
    openFeedbackSlideOver(state) {
      state.isFeedbackSlideOverOpen = true;
    },
    closeFeedbackSlideOver(state) {
      state.isFeedbackSlideOverOpen = false;
    },
    setHighlightedActuatorId(state, actuatorId) {
      state.highlightedActuatorId = actuatorId;
    },
    setDailyAccumulatedKwh(state, value) {
      state.dailyAccumulatedKwh = value;
    },
    setMonthlyAccumulatedKwh(state, value) {
      state.monthlyAccumulatedKwh = value;
    },
    setDailyAverageKwh(state, value) {
      state.dailyAverageKwh = value;
    },
    setActuatorsGridHeight(state, value) {
      state.actuatorsGridHeight = value;
    },
    setStatsInlineHeight(state, value) {
      state.statsInlineHeight = value;
    },
    setLoadingEnergyMeters(state, isLoading) {
      state.loadingEnergyMeters = isLoading;
    },
  },

  actions: {
    async login({ commit, dispatch }, email, password) {
      const response = await authClient.post(email, password);
      if (response.data.requires2fa) {
        return response.data;
      }
      localStorage.setItem("refreshToken", response.data.refresh);
      localStorage.setItem("accessToken", response.data.access);
      commit("setIsLoggedIn", true);
      dispatch("restoreSession");
    },
    async verify2FA({ commit, dispatch }, { id, token }) {
      const response = await authClient.twoFactor({ id, token });
      localStorage.setItem("refreshToken", response.data.refresh);
      localStorage.setItem("accessToken", response.data.access);
      commit("setIsLoggedIn", true);
      dispatch("restoreSession");
    },
    async restoreSession({ commit, dispatch }) {
      const token = localStorage.getItem("accessToken");
      if (token) {
        try {
          commit("setIsLoggedIn", true);
          const me = await authClient.get();
          commit("setUser", me.data);
        } catch (e) {
          dispatch("logout");
          location.reload();
        }
      } else {
        commit("setIsLoggedIn", false);
      }
    },
    logout({ commit }) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      commit("setIsLoggedIn", false);
      commit("setUser", {});
    },
    async updateSensorConfiguration(ctx, { sensor_id, payload }) {
      await sensorsConfigurationClient.update(sensor_id, payload);
    },
    async updateVenueConfig({ commit }, { id, payload }) {
      const response = await venuesClient.update(id, payload);
      commit("updateVenueConfig", response.data);
    },
    async loadVenues({ state, getters, commit }) {
      state.loadingVenues = true;
      const response = await venuesClient.list();
      commit("setVenues", response.data);
      if (getters.defaultVenue === undefined) {
        const defaultVenueDummy = {
          id: "dummy",
          name: "Todavía no tienes venues",
          numberOfActuators: 0,
          numberOfSensors: 0,
          numberOfUsers: 0,
          activeFrom: "00:00:00",
          activeTo: "23:59:59",
        };
        commit("setCurrentVenue", defaultVenueDummy);
      } else {
        commit("setCurrentVenue", getters.defaultVenue);
      }
      state.loadingVenues = false;
    },
    async clearVenueInfo({ commit }) {
      commit("setVenueInfo", {});
      commit("setVenueInfos", []);
    },
    async clearSensorInfo({ commit }) {
      commit("setSensorInfo", {});
      commit("setSensorInfos", []);
    },
    async loadVenueInfo({ state, dispatch, commit }) {
      dispatch("clearVenueInfo");
      const response = await venueInfoClient.retrieve(
        state.currentVenue.id,
        state.selectedDate.toISOString(),
        moment(state.selectedDate.toISOString()).add("1", "days").toISOString()
      );
      const listResponse = await venueInfoClient.list(
        state.currentVenue.id,
        state.selectedDate.toISOString()
      );
      if (response.data) commit("setVenueInfo", response.data);
      if (listResponse.data) commit("setVenueInfos", listResponse.data);
    },
    async loadSensorInfo({ state, dispatch, commit }) {
      let response;
      let listResponse;
      dispatch("clearSensorInfo");
      try {
        response = await sensorInfoClient.retrieve(
          state.currentSensor.id,
          state.selectedDate.toISOString(),
          moment(state.selectedDate.toISOString())
            .add("1", "days")
            .toISOString()
        );
        listResponse = await sensorInfoClient.list(
          state.currentSensor.id,
          state.selectedDate.toISOString(),
          moment(state.selectedDate.toISOString())
            .add("1", "days")
            .toISOString()
        );
        commit("setSensorInfo", response.data);
        commit("setSensorInfos", listResponse.data);
      } catch {
        //
      }
    },
    async loadVenueActuator({ commit }, actuatorId) {
      const response = await actuatorsClient.get(actuatorId);
      commit("setVenueActuator", response.data);
    },
    async loadVenueActuators({ state, commit }) {
      state.loadingActuators = true;
      commit("setLastUpdatedActuators", moment().format("HH:mm"));
      const response = await actuatorsClient.getAllByVenue(
        state.currentVenue.id
      );
      response.data.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
      );
      await this.dispatch("loadSetpointPresets");
      commit("setVenueActuators", response.data);
      state.loadingActuators = false;
    },
    async updateActuator({ dispatch }, { id, data }) {
      await actuatorsClient.update(id, data);
      await dispatch("loadVenueActuator", id);
    },
    async createSetpointPreset({ dispatch }, { id, data }) {
      await actuatorsClient.createSetpointPreset(id, data);
      await dispatch("loadVenueActuators");
    },
    async activateMaintenanceMode({ dispatch }, { id, data }) {
      await actuatorsClient.activateMaintenanceMode(id, data);
      await dispatch("loadVenueActuator", id);
    },
    async emergencyShutdown({ dispatch, state }) {
      await actuatorsClient.emergencyShutdown(state.currentVenue.id);
      await dispatch("loadVenueActuators");
    },
    async loadSetpointPresets({ commit }) {
      const response = await actuatorsClient.getAllSetpointPresets();
      commit("setSetpointPresets", response.data);
    },
    async loadActuatorLogs({ commit }, actuatorId) {
      const response = await actuatorsClient.getLogs(actuatorId);
      commit("setActuatorLogs", response.data);
    },
    async loadActuatorsAlerts({ commit }, actuatorId) {
      const response = await actuatorsClient.getAlerts(actuatorId);
      commit("setActuatorsAlerts", response.data);
    },
    async loadVenueActuatorsAlerts({ commit, state }) {
      const response = await actuatorsClient.getAlertsByVenue(
        state.currentVenue.id
      );
      commit("setVenueActuatorsAlerts", response.data);
    },
    async loadVenueGrid({ state, commit }) {
      commit("setLastUpdatedSensors", moment().format("HH:mm"));
      let response;
      response = await venueGridClient.list(
        state.currentVenue.id,
        state.selectedDate.toISOString(),
        state.selectedDate.isSame(moment(), "day")
      );
      commit("setVenueGrid", response.data);
    },
    selectVenue({ commit }, venue) {
      commit("setCurrentVenue", venue);
    },
    async selectSensor({ state, commit, dispatch }, sensor) {
      if (state.currentSensor)
        this.state.indexedSensorQueryData[state.currentSensor.id] = undefined;
      commit("setCurrentSensor", sensor);
      await dispatch("loadNewSensorData", state.currentSensor);
    },
    async loadSensorQueryData({ state, commit, dispatch }, { sensor }) {
      const response = await sensorsClient.retrieve(sensor.id, {
        from: state.selectedDate.toISOString(),
        to: moment(state.selectedDate.toISOString())
          .add("1", "days")
          .toISOString(),
      });
      commit("setSensorQueryData", response.data);
      await dispatch("assignData");
      await dispatch("loadSensorInfo", {
        sensor,
        from_: state.selectedDate.toISOString(),
      });
    },
    async loadNewSensorData({ getters, dispatch }, sensor) {
      let date;
      if (getters.sensorHasData(sensor)) {
        date = getters.nthSensorDatum(sensor, -1).date;
      } else {
        date = moment();
        date.subtract(1, "days");
      }
      await dispatch("loadSensorQueryData", { sensor });
      await dispatch("loadSensorInfo", { sensor, from_: date });
    },
    async loadSensorConfiguration({ commit }, { sensor }) {
      const response = await sensorsConfigurationClient.retrieve(sensor.id);
      commit("setSensorConfiguration", response.data);
    },
    async resetPassword(ctx, email) {
      return await usersClient.reset(email);
    },

    assignData({ state, getters }) {
      const rawData = getters.sensorQueryDataArray(state.currentSensor);

      // Common variables for all sensor types
      state.temperatureTimeSeries.data = rawData.map(({ date, values }) => ({
        date,
        value: values.temperature,
      }));
      state.humidityTimeSeries.data = rawData.map(({ date, values }) => ({
        date,
        value: values.humidity,
      }));
      state.co2TimeSeries.data = rawData.map(({ date, values }) => ({
        date,
        value: values.co2,
      }));

      state.nh3TimeSeries.data = rawData.map(({ date, values }) => ({
        date,
        value: values.nh3,
      }));
      state.h2sTimeSeries.data = rawData.map(({ date, values }) => ({
        date,
        value: values.h2s,
      }));

      // Variables specific to ws523
      state.voltageTimeSeries.data = rawData.map(({ date, values }) => ({
        date,
        value: values.voltage,
      }));
      state.powerTimeSeries.data = rawData.map(({ date, values }) => ({
        date,
        value: values.power,
      }));
      state.currentTimeSeries.data = rawData.map(({ date, values }) => ({
        date,
        value: values.current,
      }));
      state.energySumTimeSeries.data = rawData.map(({ date, values }) => ({
        date,
        value: values.energySum,
      }));
      state.factorTimeSeries.data = rawData.map(({ date, values }) => ({
        date,
        value: values.factor,
      }));
      state.stateTimeSeries.data = rawData.map(({ date, values }) => ({
        date,
        value: values.state,
      }));
    },

    async createUser(ctx, payload) {
      return await usersClient.create(payload);
    },
    async changePassword(ctx, oldPassword, newPassword) {
      return await usersClient.change_password(oldPassword, newPassword);
    },
    async requestReport(ctx, { venue_id, body }) {
      return await reportsClient.request(venue_id, body);
    },
    async submitFeedback(ctx, payload) {
      await feedbackClient.create(payload);
    },
    async updateActuatorAlert(ctx, { id, data }) {
      await actuatorsClient.markAlertAsReal(id, data);
    },
    async getDailyAccumulatedKwhForVenue({ commit }, { venueId, target_day }) {
      const response = await energyMetersClient.getDailyAccumulatedKwhForVenue(
        venueId,
        target_day
      );
      commit("setDailyAccumulatedKwh", response.data);
    },
    async getMonthlyAccumulatedKwhForVenue(
      { commit },
      { venueId, target_month }
    ) {
      const response =
        await energyMetersClient.getMonthlyAccumulatedKwhForVenue(
          venueId,
          target_month
        );
      commit("setMonthlyAccumulatedKwh", response.data);
    },
    async getDailyAverageKwhForVenue({ commit }, { venueId, target_day }) {
      const response = await energyMetersClient.getDailyAverageKwhForVenue(
        venueId,
        target_day
      );
      commit("setDailyAverageKwh", response.data);
    },
    async getEnergyMetersData({ dispatch, commit }, { venueId, target_day }) {
      commit("setLoadingEnergyMeters", true);
      await dispatch("getDailyAccumulatedKwhForVenue", {
        venueId,
        target_day,
      });
      await dispatch("getMonthlyAccumulatedKwhForVenue", {
        venueId,
        target_month: target_day,
      });
      await dispatch("getDailyAverageKwhForVenue", {
        venueId,
        target_day,
      });
      commit("setLoadingEnergyMeters", false);
    },
    async createSchedule(ctx, { payload, scheduleType }) {
      payload.content_type = scheduleType;
      const response = await actuatorsClient.createSchedule(
        payload,
        scheduleType
      );
      await this.dispatch("loadVenueActuators");
      return response;
    },
    async updateSchedule(ctx, { id, payload, scheduleType }) {
      payload.content_type = scheduleType;
      const response = await actuatorsClient.updateSchedule(
        scheduleType,
        id,
        payload
      );
      await this.dispatch("loadVenueActuators");
      return response;
    },
    async deleteSchedule(ctx, { id, scheduleType }) {
      const response = await actuatorsClient.deleteSchedule(scheduleType, id);
      await this.dispatch("loadVenueActuators");
      return response;
    },
    async createSetpointSchedule(ctx, { payload }) {
      const response = await actuatorsClient.createSetpointSchedule(payload);
      await this.dispatch("loadVenueActuators");
      return response;
    },
    async updateSetpointSchedule(ctx, { id, payload }) {
      const response = await actuatorsClient.updateSetpointSchedule(
        id,
        payload
      );
      await this.dispatch("loadVenueActuators");
      return response;
    },
    async deleteSetpointSchedule(ctx, id) {
      const response = await actuatorsClient.deleteSetpointSchedule(id);
      await this.dispatch("loadVenueActuators");
      return response;
    },
    updateStatsInLineHeight({ commit }, value) {
      commit("setStatsInlineHeight", value);
    },
    updateActuatorsGridHeight({ commit }, value) {
      commit("setActuatorsGridHeight", value);
    },
    async deleteActuatorAlert({ dispatch }, { id, alerts }) {
      await actuatorsClient.deleteAlert(id, alerts);
      dispatch("loadVenueActuatorsAlerts");
    },
  },
});
