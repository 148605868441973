<template>
  <div class="relative p-4">
    <transition
      enter-active-class="transition-opacity duration-500"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="notification.message && notification.show"
        :class="{
          'border-green-400 bg-green-100 text-green-800':
            notification.type === 'success',
          'border-red-400 bg-red-100 text-red-800':
            notification.type === 'error',
        }"
        class="absolute left-0 right-0 top-0 mb-1.5 flex items-center justify-between space-x-2 rounded-md border-l-4 p-3"
        style="z-index: 10"
      >
        <div class="flex items-center space-x-2">
          <font-awesome-icon
            :icon="
              notification.type === 'success'
                ? 'circle-check'
                : 'circle-exclamation'
            "
            class="h-5 w-5"
          />
          <span>{{ notification.message }}</span>
        </div>
        <button class="focus:outline-none" @click="clearNotification">
          <font-awesome-icon icon="xmark" class="h-4 w-4" />
        </button>
      </div>
    </transition>
    <div
      v-if="loadingActuators"
      class="flex h-full flex-col items-center justify-center"
    >
      <font-awesome-icon
        icon="spinner"
        class="h-12 w-12 animate-spin text-gray-600"
      />
    </div>
    <div v-if="!loadingActuators">
      <div v-if="!showForm" class="mt-16">
        <div v-if="schedules.length === 0" class="text-center text-gray-500">
          No tienes calendarizaciones para este actuador.
        </div>
        <ul class="divide-y divide-gray-200">
          <li
            v-for="schedule in schedules"
            :key="schedule.id"
            class="flex cursor-pointer flex-col p-3 hover:bg-gray-100"
            @click="selectSchedule(schedule)"
          >
            <div class="flex items-center justify-between">
              <h3 class="text-lg font-semibold text-gray-900">
                {{ schedule.name }}
              </h3>
              <div class="space-x-2">
                <span
                  v-if="schedule.isCurrentlyActive"
                  class="flex items-center rounded-full bg-blue-200 px-3 py-1 text-sm font-medium text-blue-600"
                >
                  <font-awesome-icon icon="clock" class="mr-2 h-4 w-4" />
                  Activo ahora
                </span>
                <span
                  v-else-if="schedule.active"
                  class="flex items-center rounded-full bg-green-200 px-3 py-1 text-sm font-medium text-green-600"
                >
                  <font-awesome-icon icon="circle-check" class="mr-2 h-4 w-4" />
                  Habilitado
                </span>
                <span
                  v-else
                  class="flex items-center rounded-full bg-gray-200 px-3 py-1 text-sm font-medium text-gray-600"
                >
                  <font-awesome-icon
                    icon="circle-exclamation"
                    class="mr-2 h-4 w-4"
                  />
                  Deshabilitado
                </span>
              </div>
            </div>
            <div class="mt-1 text-sm text-gray-700">
              Tipo: {{ mapScheduleType(schedule.type) }}
            </div>
            <div
              v-if="schedule.type === 'setpoint' && schedule.setpointPreset"
              class="mt-1 text-sm text-gray-700"
            >
              {{ schedule.setpointPreset.name }}:
              <span class="text-gray-500">
                {{ schedule.setpointPreset.setpointTempLow }}º -
                {{ schedule.setpointPreset.setpointTempHigh }}º
              </span>
            </div>
            <div
              v-if="schedule.type === 'unitmode'"
              class="mt-1 text-sm text-gray-700"
            >
              Modo: {{ schedule.unitMode }}
            </div>
            <div
              v-if="schedule.type === 'onoff'"
              class="mt-1 text-sm text-gray-700"
            >
              Estado: {{ schedule.onOff === "on" ? "Encendido" : "Apagado" }}
            </div>
            <div
              v-if="schedule.startTime && schedule.endTime"
              class="mt-1 text-xs text-gray-500"
            >
              Horario: {{ schedule.startTime.slice(0, 5) }} -
              {{ schedule.endTime.slice(0, 5) }}
            </div>
          </li>
        </ul>
        <button
          class="mt-4 w-full rounded-md bg-blue-600 py-2 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          @click="createNewSchedule"
        >
          Crear Calendarización
        </button>
      </div>
      <ScheduleForm
        v-if="showForm"
        :key="selectedSchedule?.id"
        :schedule="selectedSchedule"
        :actuator="actuator"
        :is-recurring="isRecurring"
        :selected-dates="selectedDates"
        :recurrent-schedule-settings="recurrentScheduleSettings"
        :schedule-type="selectedSchedule?.type || type"
        :is-type-read-only="selectedSchedule !== null"
        class="mt-4"
        @create="createSchedule"
        @update="updateSchedule"
        @cancel="cancelEdit"
        @delete="deleteSchedule"
      />
    </div>
  </div>
</template>

<script>
import ScheduleForm from "./ScheduleForm.vue";

export default {
  components: {
    ScheduleForm,
  },
  props: {
    actuator: {
      type: Object,
      required: true,
    },
    isRecurring: {
      type: Boolean,
      default: false,
    },
    selectedDates: {
      type: Array,
      default: () => [],
    },
    recurrentScheduleSettings: {
      type: Object,
      default: () => ({
        selectedDays: [],
        frequency: 1,
        intervalUnit: "weeks",
        endDate: new Date().toISOString().substring(0, 10),
      }),
    },
    scheduleType: {
      type: String,
      default: "setpoint",
    },
  },
  emits: ["schedule-selected", "switching-form"],
  data() {
    return {
      selectedSchedule: null,
      creatingNewSchedule: false,
      showForm: false,
      notification: {
        show: false,
        message: "",
        type: "",
      },
      notificationTimeoutId: null,
    };
  },
  computed: {
    setpointPresets() {
      return this.$store.state.setpointPresets;
    },
    actuatorIndex() {
      return this.$store.state.venueActuators.findIndex(
        (actuator) => actuator.id === this.$store.state.selectedActuatorId
      );
    },
    schedules() {
      const filteredSchedules =
        this.actuator?.schedules?.filter(
          (schedule) => schedule.isRecurring === this.isRecurring
        ) || [];

      return filteredSchedules.sort((a, b) => {
        if (a.isCurrentlyActive && !b.isCurrentlyActive) return -1;
        if (!a.isCurrentlyActive && b.isCurrentlyActive) return 1;

        if (a.active && !b.active) return -1;
        if (!a.active && b.active) return 1;

        return 0;
      });
    },
    loadingActuators() {
      return this.$store.state.loadingActuators;
    },
  },
  watch: {
    isRecurring: {
      immediate: true,
      handler() {
        this.cancelEditIfFormOpen();
      },
    },
  },
  methods: {
    mapScheduleType(type) {
      const typeMapping = {
        setpoint: "Setpoint",
        onoff: "Encendido/Apagado",
        controlcycle: "Ciclo de control",
        unitmode: "Modo de operación",
        ecomode: "Modo Económico",
      };
      return typeMapping[type] || type;
    },
    clearNotification() {
      this.notification = { message: "", type: "" };
    },
    selectSchedule(schedule) {
      this.clearNotification();
      this.selectedSchedule = schedule;
      this.showForm = true;
      this.$emit("schedule-selected", schedule);
    },
    createNewSchedule() {
      this.clearNotification();
      this.creatingNewSchedule = true;
      this.selectedSchedule = null;
      this.showForm = true;
      this.$emit("switching-form");
    },
    async updateSchedule({ schedule, scheduleType }) {
      try {
        const response = await this.$store.dispatch("updateSchedule", {
          id: schedule.id,
          payload: schedule,
          scheduleType: scheduleType,
        });
        if (response && response.status === 200) {
          const message = `Calendarización ${schedule.name} actualizada exitosamente.`;
          this.displayNotification(message, "success");
          this.cancelEdit();
        } else {
          this.handleErrorResponse(response, schedule.name);
        }
      } catch (error) {
        this.handleErrorResponse(error.response?.data, schedule.name);
      }
    },
    async createSchedule({ schedule, scheduleType }) {
      try {
        const response = await this.$store.dispatch("createSchedule", {
          scheduleType: scheduleType,
          payload: schedule,
        });
        if (response && response.status === 201) {
          this.displayNotification(
            `Calendarización ${schedule.name} creada exitosamente.`,
            "success"
          );
          this.cancelEdit();
        } else {
          this.handleErrorResponse(response, schedule.name);
        }
      } catch (error) {
        this.handleErrorResponse(error.response?.data, schedule.name);
      }
    },
    handleErrorResponse(response, scheduleName) {
      if (response && response.conflicts) {
        const message =
          response.conflicts.length > 1
            ? `Hay conflictos con las calendarizaciones: ${response.conflicts.join(
                ", "
              )}.`
            : `Hay conflictos con la calendarización: ${response.conflicts[0]}.`;
        this.displayNotification(message, "error");
      } else {
        this.displayNotification(
          `Hubo un error al procesar la calendarización ${scheduleName}.`,
          "error"
        );
      }
    },
    cancelEditIfFormOpen() {
      if (this.creatingNewSchedule || this.selectedSchedule) {
        this.cancelEdit();
        this.showForm = false;
      }
    },
    cancelEdit() {
      this.showForm = false;
      this.creatingNewSchedule = false;
      this.selectedSchedule = null;
      this.$emit("switching-form");
    },
    deleteSchedule({ schedule }) {
      this.displayNotification(
        `Calendarización ${schedule.name} eliminada exitosamente.`,
        "success"
      );
      this.cancelEdit();
    },
    displayNotification(message, type) {
      if (this.notificationTimeoutId) {
        clearTimeout(this.notificationTimeoutId);
      }
      this.notification = { message, type, show: true };
      this.notificationTimeoutId = setTimeout(() => {
        this.notification = { message: "", type: "", show: false };
      }, 5000);
    },
  },
};
</script>
